<template>
  <el-popover
    v-bind="{ ...$attrs, ...props }"
    effect="dark"
    :popper-class="['ui-popover', props.popperClass || '']"
  >
    <template v-if="$slots.reference" #reference>
      <slot name="reference"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-popover>
</template>
<script setup lang="ts">
import { popoverProps } from 'element-plus'
const props = defineProps({
  ...popoverProps,
  offset: {
    type: Number,
    default: 4,
  },
  showAfter: {
    type: Number,
    default: 300,
  },
})
</script>
<!-- eslint-disable vue/enforce-style-attribute -->
<style lang="postcss">
.ui-popover.el-popover.el-popper,
.ui-popover.el-popover.el-popper.is-dark {
  --ui-popover-border-color: transparent;
  --ui-popover-bg-color: var(--brand-color-base-tertiary);
  --ui-popover-text-color: var(--brand-color-text-tertiary);
  --ui-popover-title-text-color: var(--brand-color-text-primary);
  --ui-popover-border-radius: 0.75rem; /* 12px */
  --ui-popover-padding: 0.75rem; /* 12px */

  --el-popover-bg-color: var(--ui-popover-bg-color);
  --el-popover-border-color: var(--ui-popover-border-color);
  --el-popover-title-text-color: var(--ui-popover-title-text-color);
  --el-popover-font-size: 0.75rem; /* 12px */
  --el-popover-line-height: 1rem; /* 16px */
  --el-popover-title-font-size: 0.75rem; /* 12px */
  --el-popover-border-radius: var(--ui-popover-border-radius);
  --el-popover-padding: var(--ui-popover-padding);
  --el-popover-padding-large: var(--ui-popover-padding);

  display: flex;
  flex-direction: column;
  gap: 0.75rem; /* 12px */
  font-family: var(--font-family-primary);
  font-weight: 300;
  line-height: var(--el-popover-line-height);
  color: var(--ui-popover-text-color);
  background: var(--ui-popover-bg-color);
  border: none;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}
.ui-popover.el-popper .el-popper__arrow::before,
.ui-popover.el-popper.is-dark .el-popper__arrow::before {
  background: var(--ui-popover-bg-color);
  border-color: var(--ui-popover-border-color);
  border-top-left-radius: 0;
}
.el-popover__title {
  margin-bottom: 0;
  font-weight: 400;
  line-height: var(--el-popover-line-height);
  color: var(--brand-color-text-secondary);
}
</style>
